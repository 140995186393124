var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bookArticle" },
    _vm._l(_vm.article, function(item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "bookArticle__phase",
          attrs: { id: item.id }
        },
        [
          _c("div", { staticClass: "bookArticle__phase__title" }, [
            item.level == 1
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/運勢書共用/第一層標題icon.svg")
                  }
                })
              : item.level == 2
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/運勢書共用/第二層標題icon.svg")
                  }
                })
              : _vm._e(),
            _vm._v("\n\n      " + _vm._s(item.title) + "\n    ")
          ]),
          _c("div", {
            staticClass: "bookArticle__phase__word",
            domProps: { innerHTML: _vm._s(item.word) }
          })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }