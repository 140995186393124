var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabHandler", class: { isTabFixed: _vm.isTabFixed } },
    [
      _c(
        "div",
        { staticClass: "tabHandler__tabContainer" },
        _vm._l(_vm.tab, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "tabHandler__tabContainer__tab",
              class: { active: _vm.currentRouteName === item.routeName },
              on: {
                click: function($event) {
                  return _vm.$emit("changeTab", item.routeName)
                }
              }
            },
            [
              _vm.book2024Preorder && item.routeName !== "Book2024ContentTrend"
                ? _c("img", {
                    attrs: { src: require("@/assets/運勢書共用/tab_icon.svg") }
                  })
                : _vm._e(),
              _c("span", { domProps: { innerHTML: _vm._s(item.name) } })
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }