<template>
  <div class="tabHandler" :class="{ isTabFixed }">
    <div class="tabHandler__tabContainer">
      <div
        class="tabHandler__tabContainer__tab"
        :class="{ active: currentRouteName === item.routeName }"
        v-for="item in tab"
        :key="item.id"
        @click="$emit('changeTab', item.routeName)"
      >
        <img
          src="@/assets/運勢書共用/tab_icon.svg"
          v-if="book2024Preorder && item.routeName !== 'Book2024ContentTrend'"
        />
        <span v-html="item.name"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isTabFixed: {
      type: Boolean,
      required: true,
    },
    nowAstroCN: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tab: [
        {
          name: `2024 <br class="hidden-laptop-up">大趨勢`,
          routeName: "Book2024ContentTrend",
        },
        {
          name: `過去挑戰<br class="hidden-laptop-up">和盲點`,
          routeName: "Book2024ContentPast",
        },
        {
          name: `2024<br class="hidden-laptop-up">的機會`,
          routeName: "Book2024ContentFuture",
        },
        {
          name: `給${this.nowAstroCN}座<br class="hidden-laptop-up">的話`,
          routeName: "Book2024ContentImprove",
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    book2024Preorder() {
      return this.$store.state.book2024_preorder;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.tabHandler {
  margin: 0 auto;
  width: 100%;
  @include laptop {
    padding: 0;
  }
  &.isTabFixed {
    position: fixed;
    top: 95px;
    left: 0;
    right: 0;
    z-index: 54; // mobile menu is 55
    background: #214c73;
    @include laptop {
      top: 60px;
      background: #0c2f58;
    }
  }
  &__tabContainer {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 720px;
    border-bottom: 1px solid #e5e5e5;
    @include laptop {
      justify-content: space-around;
    }
    &__tab {
      cursor: pointer;
      padding: 24px 4px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.65px;
      &:not(:nth-last-of-type()) {
        margin-right: 44px;
        @include laptop {
          margin-right: 0;
        }
      }
      @include laptop {
        padding: 8px 10px 12px;
        width: auto;
        font-size: 13px;
        position: relative;
      }
      &.active {
        pointer-events: none;
        font-weight: 700;
        position: relative;
        color: #fff;
        &:after {
          content: "";
          position: absolute;
          bottom: -1.5px;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #d3409d;
          border-radius: 4px;
        }
      }

      img {
        width: 16px;
        margin-right: 8px;
        @include laptop {
          position: absolute;
          top: -4px;
          left: -4px;
        }
      }
    }
  }
}
</style>
