var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.astroId, staticClass: "bookContentWrap" },
    [
      _c("div", { staticClass: "bookContentWrap__head" }, [
        _c("div", { staticClass: "bookContentWrap__head__pic" }, [
          _c("img", { attrs: { src: _vm.nowProductObj.bookImg, alt: "" } })
        ]),
        _c("div", { staticClass: "bookContentWrap__head__title" }, [
          _vm._v(_vm._s(_vm.nowProductObj.cn) + "座")
        ]),
        _c("div", { staticClass: "bookContentWrap__head__detail" }, [
          _c("div", { staticClass: "bookContentWrap__head__detail__date" }, [
            _vm._v("\n        " + _vm._s(_vm.nowProductObj.date) + "\n      ")
          ]),
          _c("div", { staticClass: "bookContentWrap__head__detail__enName" }, [
            _vm._v("\n        " + _vm._s(_vm.nowProductObj.en) + "\n      ")
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "switchConstellation hidden-mobile-down",
            attrs: { tabindex: "0" },
            on: {
              blur: function($event) {
                _vm.switchConstellationOpen = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "switchConstellation__btn",
                on: {
                  click: function($event) {
                    _vm.switchConstellationOpen = !_vm.switchConstellationOpen
                  }
                }
              },
              [
                _vm._v("\n        星座列表\n        "),
                _c("img", {
                  class: { rotate: _vm.switchConstellationOpen },
                  attrs: {
                    src: require("@/assets/運勢書共用/目錄展開收和紐.svg")
                  }
                })
              ]
            ),
            _vm.switchConstellationOpen
              ? _c(
                  "div",
                  { staticClass: "switchConstellation__list" },
                  _vm._l(_vm.book2024_list, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.en,
                        staticClass: "switchConstellation__list__item",
                        on: {
                          click: function($event) {
                            return _vm.toBuyOrRead(item)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "switchConstellation__list__item__pic"
                          },
                          [
                            _c("img", { attrs: { src: item.bookImg } }),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.en === _vm.astroId,
                                  expression: "item.en === astroId"
                                }
                              ],
                              staticClass: "current",
                              attrs: {
                                src: require("@/assets/運勢書共用/切換星座current.svg")
                              }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "switchConstellation__list__item__title"
                          },
                          [
                            !item.isUnlock
                              ? _c("img", {
                                  staticClass: "locked",
                                  attrs: {
                                    src: require("@/assets/運勢書共用/icon-locked.svg")
                                  }
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(item.cn) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]
        )
      ]),
      _c("TabHandler", {
        attrs: { isTabFixed: _vm.isTabFixed, nowAstroCN: _vm.nowProductObj.cn },
        on: { changeTab: _vm.changeTab }
      }),
      _c("AnchorHandler", {
        attrs: { article: _vm.article, mobileHide: !_vm.isTabFixed }
      }),
      _c(
        "div",
        { staticClass: "bookContent", class: { isTabFixed: _vm.isTabFixed } },
        [_c("BookArticle", { attrs: { article: _vm.article } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPreorderToast,
              expression: "showPreorderToast"
            }
          ],
          staticClass: "preorderToast"
        },
        [
          _vm._v("\n    預購內容即將上線\n    "),
          _c("span", { staticClass: "hidden-mobile-down" }, [_vm._v("，")]),
          _c("br", { staticClass: "hidden-mobile-up" }),
          _vm._v("\n    請敬請期待\n  ")
        ]
      ),
      _c(
        "div",
        { staticClass: "mobileSwitchConstellations hidden-mobile-up" },
        [
          _c(
            "div",
            {
              staticClass: "mobileSwitchConstellations__title",
              on: {
                click: function($event) {
                  _vm.switchConstellationOpen = !_vm.switchConstellationOpen
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.nowProductObj.cn) + "座\n\n      "
              ),
              _c("img", {
                class: { rotate: _vm.switchConstellationOpen },
                attrs: {
                  src: require("@/assets/運勢書共用/目錄展開收和紐.svg")
                }
              })
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switchConstellationOpen,
                  expression: "switchConstellationOpen"
                }
              ],
              staticClass: "mobileSwitchConstellations__body"
            },
            [
              _c(
                "div",
                { staticClass: "mobileSwitchConstellations__body__list" },
                _vm._l(_vm.book2024_list, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.en,
                      staticClass:
                        "mobileSwitchConstellations__body__list__item",
                      on: {
                        click: function($event) {
                          return _vm.toBuyOrRead(item)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mobileSwitchConstellations__body__list__item__pic"
                        },
                        [
                          item.isUnlock
                            ? _c("img", { attrs: { src: item.bookImg } })
                            : _c("img", {
                                staticClass: "locked",
                                attrs: {
                                  src: require("@/assets/運勢書共用/icon-locked.svg")
                                }
                              }),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.en === _vm.astroId,
                                expression: "item.en === astroId"
                              }
                            ],
                            staticClass: "current",
                            attrs: {
                              src: require("@/assets/運勢書共用/切換星座current.svg")
                            }
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mobileSwitchConstellations__body__list__item__title"
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item.cn) + "\n          "
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              ),
              !_vm._buyAllObj.isUnlock
                ? _c(
                    "div",
                    { staticClass: "mobileSwitchConstellations__body__buyAll" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mobileSwitchConstellations__body__buyAll__btn",
                          on: {
                            click: function($event) {
                              return _vm.toBuyAll()
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mobileSwitchConstellations__body__buyAll__btn__text"
                            },
                            [_vm._v("\n            全部購買 NT400\n          ")]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }