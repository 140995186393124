<template>
  <div class="bookContentWrap" :key="astroId">
    <div class="bookContentWrap__head">
      <div class="bookContentWrap__head__pic">
        <img :src="nowProductObj.bookImg" alt="" />
      </div>
      <div class="bookContentWrap__head__title">{{ nowProductObj.cn }}座</div>
      <div class="bookContentWrap__head__detail">
        <div class="bookContentWrap__head__detail__date">
          {{ nowProductObj.date }}
        </div>
        <div class="bookContentWrap__head__detail__enName">
          {{ nowProductObj.en }}
        </div>
      </div>
      <!-- 切換當前星座(電腦版) start -->
      <div
        class="switchConstellation hidden-mobile-down"
        tabindex="0"
        @blur="switchConstellationOpen = false"
      >
        <div
          class="switchConstellation__btn"
          @click="switchConstellationOpen = !switchConstellationOpen"
        >
          星座列表
          <img
            src="@/assets/運勢書共用/目錄展開收和紐.svg"
            :class="{ rotate: switchConstellationOpen }"
          />
        </div>
        <div class="switchConstellation__list" v-if="switchConstellationOpen">
          <div
            class="switchConstellation__list__item"
            v-for="item in book2024_list"
            :key="item.en"
            @click="toBuyOrRead(item)"
          >
            <div class="switchConstellation__list__item__pic">
              <img :src="item.bookImg" />
              <img
                src="@/assets/運勢書共用/切換星座current.svg"
                class="current"
                v-show="item.en === astroId"
              />
            </div>
            <div class="switchConstellation__list__item__title">
              <img
                src="@/assets/運勢書共用/icon-locked.svg"
                class="locked"
                v-if="!item.isUnlock"
              />
              {{ item.cn }}
            </div>
          </div>
        </div>
      </div>
      <!-- 切換當前星座(電腦版) end -->
    </div>
    <TabHandler
      @changeTab="changeTab"
      :isTabFixed="isTabFixed"
      :nowAstroCN="nowProductObj.cn"
    />
    <AnchorHandler :article="article" :mobileHide="!isTabFixed" />
    <div class="bookContent" :class="{ isTabFixed }">
      <BookArticle :article="article" />
    </div>

    <div class="preorderToast" v-show="showPreorderToast">
      預購內容即將上線
      <span class="hidden-mobile-down">，</span>
      <br class="hidden-mobile-up" />
      請敬請期待
    </div>

    <!-- 切換當前星座(手機) -->
    <div class="mobileSwitchConstellations hidden-mobile-up">
      <div
        class="mobileSwitchConstellations__title"
        @click="switchConstellationOpen = !switchConstellationOpen"
      >
        {{ nowProductObj.cn }}座

        <img
          src="@/assets/運勢書共用/目錄展開收和紐.svg"
          :class="{ rotate: switchConstellationOpen }"
        />
      </div>
      <div
        class="mobileSwitchConstellations__body"
        v-show="switchConstellationOpen"
      >
        <div class="mobileSwitchConstellations__body__list">
          <div
            class="mobileSwitchConstellations__body__list__item"
            v-for="item in book2024_list"
            :key="item.en"
            @click="toBuyOrRead(item)"
          >
            <div class="mobileSwitchConstellations__body__list__item__pic">
              <img :src="item.bookImg" v-if="item.isUnlock" />
              <img
                src="@/assets/運勢書共用/icon-locked.svg"
                class="locked"
                v-else
              />
              <img
                src="@/assets/運勢書共用/切換星座current.svg"
                class="current"
                v-show="item.en === astroId"
              />
            </div>
            <div class="mobileSwitchConstellations__body__list__item__title">
              {{ item.cn }}
            </div>
          </div>
        </div>
        <div
          class="mobileSwitchConstellations__body__buyAll"
          v-if="!_buyAllObj.isUnlock"
        >
          <div
            class="mobileSwitchConstellations__body__buyAll__btn"
            @click="toBuyAll()"
          >
            <div class="mobileSwitchConstellations__body__buyAll__btn__text">
              全部購買 NT400
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabHandler from "@/components/運勢書/TabHandler.vue";
import BookArticle from "@/components/運勢書/BookArticle.vue";
import AnchorHandler from "@/components/運勢書/AnchorHandler.vue";
import BuyBook2024Mixin from "@/mixin/buyBook2024Mixin.js";

export default {
  metaInfo: {
    title: "唐綺陽官方專屬星盤-2024解答之書",
    meta: [
      {
        name: "title",
        content: `唐綺陽官方專屬星盤-2024解答之書`,
      },
      {
        name: "description",
        content: `面對新世界，我們不能再用舊的自己迎接。需打破習性，新的價值觀，新的表達方式面對。你已躍躍欲試了嗎？記得喔，改變的意義不在「馬上成功」，改變是為了給自己一個新的機會，現在起，開始改變，你已拿到進入新世界的門票，加油喔！`,
      },
    ],
  },
  mixins: [BuyBook2024Mixin],
  components: {
    TabHandler,
    BookArticle,
    AnchorHandler,
  },
  data() {
    return {
      isTabFixed: false,
      showPreorderToast: false,
      showPreorderToast_timer: null,
      bookContentList: [],
      switchConstellationOpen: false,
    };
  },
  created() {
    this.initBookContent();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    astroId() {
      return this.$route.params.id;
    },
    nowProductObj() {
      return this.$store.getters.get_book2024_list.find(
        (item) => item.en === this.astroId
      );
    },
    article() {
      let routeName = this.$route.name;
      let chapterNumber;
      switch (routeName) {
        case "Book2024ContentTrend":
          chapterNumber = 1;
          break;
        case "Book2024ContentPast":
          chapterNumber = 2;
          break;
        case "Book2024ContentFuture":
          chapterNumber = 3;
          break;
        case "Book2024ContentImprove":
          chapterNumber = 4;
          break;
      }
      const chapter = this.bookContentList.find(
        (item) => item.Chapter === chapterNumber
      );
      if (!chapter) return [];

      return [
        {
          id: chapter.Chapter,
          title: chapter.Title,
          word: chapter.Content === "待補" ? "" : chapter.Content,
          level: 1,
        },
        ...chapter.SecondContentList.map((secondItem, index) => {
          return {
            id: `${secondItem.Chapter}_${index}`,
            title: `${secondItem.Title}`,
            level: 2,
            word: `${secondItem.Content}`,
          };
        }),
      ];
    },
    book2024_list() {
      return this.$store.getters.get_book2024_list.filter((item) => {
        return item.Code !== "All";
      });
    },
    book2024Preorder() {
      return this.$store.state.book2024_preorder;
    },
  },
  methods: {
    async initBookContent() {
      // BookID = 1
      let res = await this.$API.GET_BOOKCONTENT(1, this.nowProductObj.en);
      this.bookContentList = res.data.Data;
    },
    parseBookContent() {},
    changeTab(tab) {
      if (this.book2024Preorder) {
        clearTimeout(this.showPreorderToast_timer);
        this.showPreorderToast = true;
        this.showPreorderToast_timer = setTimeout(() => {
          this.showPreorderToast = false;
        }, 2000);
      } else {
        this.$router.push({
          name: tab,
        });
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // desktop: 330+95; mobile: 60
      let threshold = window.innerWidth < 1064 ? 340 : 425;
      if (scrollTop > threshold) {
        this.isTabFixed = true;
      } else {
        this.isTabFixed = false;
      }
    },
    toBuyOrRead(item) {
      if (item.isUnlock) {
        this.$router.push({
          name: "Book2024ContentTrend",
          params: { id: item.en },
        });
        this.switchConstellationOpen = false;
        this.$nextTick(() => {
          this.bookContentList = [];
          this.initBookContent();
        });
      } else {
        this.$router.push({
          name: "Book2024UnlockSingle",
          params: { id: item.en },
        });
      }
    },
    toBuyAll() {
      this.mixin_buyAll();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.bookContentWrap {
  padding-top: 0;
  padding-bottom: 20vh;
  @include mobile {
    padding-top: 0;
  }
  &__head {
    width: 377px;
    padding-top: 20px;
    padding-bottom: 65px;
    margin: 0 auto;
    position: relative;
    @include mobile {
      width: 246.994px;
      padding-top: 0;
      padding-bottom: 40px;
    }

    &__pic {
      width: 100%;
      height: 300px;
      background: url("../../assets/運勢書共用/星座內文標題裝飾.svg") top center
        no-repeat;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        height: 233.39px;
      }
      img {
        height: 110.582px;
        @include mobile {
          height: 76px;
          opacity: 0.7;
        }
      }
    }
    &__title {
      margin-top: -70px;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 3.52px;
      color: #fff;
      @include mobile {
        margin-top: -51px;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 2.56px;
      }
    }
    &__detail {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-family: Arial;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1.28px;
      @include mobile {
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      &__enName {
        position: relative;
        &:after {
          content: "";
          height: 0.5px;
          width: 172px;
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: calc(-172px - 12px);
          transform: translateY(-50%);
          @include mobile {
            width: 92px;
            left: calc(-92px - 8px);
          }
        }
      }
    }
  }

  //切換星座電腦版
  .switchConstellation {
    position: absolute;
    right: -160px;
    bottom: 64px;
    z-index: 1;
    &__btn {
      padding: 4px 16px 6px 16px;
      border-radius: 50px;
      border: 1px solid #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: 0.7px;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        transition: transform 0.3s ease;
        transform: rotate(180deg);
        &.rotate {
          transform: rotate(0deg);
        }
      }
    }
    &__list {
      position: absolute;
      right: 0;
      top: 50px;
      width: 488px;
      height: 484px;
      padding: 48px;
      border-radius: 20px;
      background: #214c73;
      box-shadow: -2px -2px 8px 0px #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;
      &__item {
        width: 68px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &__pic {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          background-color: #0c2f58;
          border: 1px solid #fff;
          @include center;
          margin-bottom: 4px;
          position: relative;
          img {
            width: 52px;
            height: 52px;
          }
          img.current {
            position: absolute;
            top: -5px;
            left: -50%;
            width: 128.003px;
            height: 78px;
          }
        }
        &__title {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 1.12px;
          display: flex;
          align-items: center;
          .locked {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

// 內文部分
.bookContent {
  width: 100%;
  padding-top: 40px;
  position: relative;
  @include laptop {
    padding-top: 44px;
  }
  &.isTabFixed {
    padding-top: calc(40px + 48px);
    @include laptop {
      padding-top: 40px;
    }
  }
}

//切換當前星座
.mobileSwitchConstellations {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  &__title {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 1.08px;
    color: #fff;
    img {
      width: 12px;
      height: 12px;
      margin-left: 8px;
      transition: transform 0.3s ease;
      transform: rotate(180deg);
      &.rotate {
        transform: rotate(0deg);
      }
    }
  }
  &__body {
    margin-top: -4px;
    width: 100vw;
    background-color: #0c2f58;
    padding: 32px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &__list {
      max-width: 305px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 32px;
      &__item {
        width: 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__pic {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background-color: #0c2f58;
          border: 1px solid #fff;
          @include center;
          margin-bottom: 4px;
          position: relative;
          img {
            width: 40px;
            height: 40px;
            &.locked {
              width: 16px;
              height: 16px;
            }
          }
          img.current {
            position: absolute;
            top: -4px;
            left: -19px; // (90-52)/2
            width: 90px;
            height: 60px;
          }
        }
        &__title {
          color: #fff;
          font-size: 13px;
          font-weight: 400;
          line-height: 200%;
          letter-spacing: 0.65px;
        }
      }
    }
    &__buyAll {
      margin-top: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      &__btn {
        width: 100%;
        max-width: 125px;
        height: 25px;
        border-radius: 50px;
        border: 1px solid #fff;
        background-color: #214c73;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.32);
        color: #fff;
        @include center;
        cursor: pointer;
        &__text {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.6px;
        }
      }
    }
  }
}

//預購視窗
.preorderToast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 390px;
  height: 112px;
  border-radius: 20px;
  border: 0.8px solid #d9d9d9;
  background: rgba(33, 76, 115, 0.6);
  backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 1.08px;

  @include mobile {
    width: 256px;
    height: 96px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1.28px;
  }
}
</style>
