<template>
  <div class="anchorHandler" :class="{ mobileHide }">
    <div class="anchorHandler__wrap">
      <div
        class="anchorHandler__wrap__header"
        @click="mobileExpand = !mobileExpand"
      >
        <div class="anchorHandler__wrap__header__title">
          <img src="@/assets/運勢書共用/目錄icon.svg" alt="" />
          目錄
          <span class="hidden-laptop-up">： {{ currentTitle }}</span>
        </div>
        <div class="anchorHandler__wrap__header__arrow hidden-laptop-up">
          <img
            src="@/assets/運勢書共用/目錄展開收和紐.svg"
            alt=""
            :class="{ rotate: mobileExpand }"
          />
        </div>
      </div>
      <div
        class="anchorHandler__wrap__list"
        :class="{ hideListMobile: !mobileExpand }"
      >
        <div
          class="anchorHandler__wrap__list__item"
          v-for="(item, index) in article"
          :key="index"
          :class="{
            isTitle: item.level == 1,
            isSubtitle: item.level == 2,
            current: activeId == item.id,
          }"
          @click="scrollTo(item.id)"
        >
          {{ item.level == 2 ? String(index).padStart(2, "0") : ""
          }}<span class="ml-1">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Array,
      default: () => [
        {
          id: "trend",
          title: "2024大趨勢",
          word: mockData.fake,
          isHighline: true,
        },
      ],
    },
    mobileHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeId: null,
      throttleTimer: null,
      mobileExpand: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.checkHighlight);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkHighlight);
  },
  computed: {
    currentTitle() {
      let artObj = this.article.find((item) => item.id === this.activeId);
      return artObj ? artObj.title : "";
    },
  },
  methods: {
    scrollTo(id) {
      let element = document.getElementById(id);
      let rect = element.getBoundingClientRect();
      let top = rect.top + window.pageYOffset - 210;
      window.scrollTo({ top: top, behavior: "smooth" });
      this.mobileExpand = false;
    },
    checkHighlight() {
      if (this.throttleTimer) return;
      this.throttleTimer = setTimeout(() => {
        let windowMiddle = window.innerHeight / 2;
        let closestId = null;

        this.article.forEach((item) => {
          const element = document.getElementById(item.id);
          const rect = element.getBoundingClientRect();
          const top = rect.top;

          if (top <= windowMiddle) {
            closestId = item.id;
          }
        });
        this.activeId = closestId;
        this.throttleTimer = null;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.anchorHandler {
  position: fixed;
  top: 219px;
  left: 24px;
  width: 235px;
  z-index: 1;
  @include laptop {
    position: fixed;
    top: 119px;
    left: 0;
    width: 100%;
  }
  &.mobileHide {
    @include laptop {
      display: none;
    }
  }
  &__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    @include laptop {
      background-color: #0c2f58;
      padding: 14px 20px;
    }
    &__header {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include laptop {
        font-size: 14px;
      }
      &__title {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      &__arrow {
        img {
          width: 12px;
          height: 12px;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
    &__list {
      max-height: 50vh;
      overflow: auto;
      padding-left: 28px;
      padding-right: 12px;
      @include scroll-primary;
      @include laptop {
        margin-top: 8px;
        padding-bottom: 18px;
      }
      &.hideListMobile {
        @include laptop {
          display: none;
        }
      }
      &__item {
        border-bottom: 0.5px solid #d5d5d5;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.8px;
        color: #fff;
        cursor: pointer;
        position: relative;
        @include laptop {
          font-size: 14px;
          padding-top: 12px;
          padding-bottom: 12px;
        }
        &.isSubtitle {
          padding-left: 12px;
          @include laptop {
            padding-left: 8px;
          }
        }
        &.current {
          &:after {
            content: "";
            width: 16px;
            height: 16px;
            background: url("../../assets/運勢書共用/章節目錄active.svg")
              no-repeat;
            position: absolute;
            top: 50%;
            left: -26px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
