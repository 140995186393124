var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "anchorHandler", class: { mobileHide: _vm.mobileHide } },
    [
      _c("div", { staticClass: "anchorHandler__wrap" }, [
        _c(
          "div",
          {
            staticClass: "anchorHandler__wrap__header",
            on: {
              click: function($event) {
                _vm.mobileExpand = !_vm.mobileExpand
              }
            }
          },
          [
            _c("div", { staticClass: "anchorHandler__wrap__header__title" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/運勢書共用/目錄icon.svg"),
                  alt: ""
                }
              }),
              _vm._v("\n        目錄\n        "),
              _c("span", { staticClass: "hidden-laptop-up" }, [
                _vm._v("： " + _vm._s(_vm.currentTitle))
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "anchorHandler__wrap__header__arrow hidden-laptop-up"
              },
              [
                _c("img", {
                  class: { rotate: _vm.mobileExpand },
                  attrs: {
                    src: require("@/assets/運勢書共用/目錄展開收和紐.svg"),
                    alt: ""
                  }
                })
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "anchorHandler__wrap__list",
            class: { hideListMobile: !_vm.mobileExpand }
          },
          _vm._l(_vm.article, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "anchorHandler__wrap__list__item",
                class: {
                  isTitle: item.level == 1,
                  isSubtitle: item.level == 2,
                  current: _vm.activeId == item.id
                },
                on: {
                  click: function($event) {
                    return _vm.scrollTo(item.id)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      item.level == 2 ? String(index).padStart(2, "0") : ""
                    )
                ),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }