<template>
  <div class="bookArticle">
    <!-- 未來的運勢 -->
    <div
      class="bookArticle__phase"
      v-for="item in article"
      :key="item.id"
      :id="item.id"
    >
      <div class="bookArticle__phase__title">
        <img
          src="@/assets/運勢書共用/第一層標題icon.svg"
          v-if="item.level == 1"
        />
        <img
          src="@/assets/運勢書共用/第二層標題icon.svg"
          v-else-if="item.level == 2"
        />

        {{ item.title }}
      </div>
      <div class="bookArticle__phase__word" v-html="item.word"></div>
    </div>
  </div>
</template>

<script>
import mockData from "@/mock/book.js";
export default {
  props: {
    article: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mockData: mockData,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.bookArticle {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  @include pad {
    padding: 0 50px;
  }
  &__phase {
    margin-bottom: 40px;
    @include mobile {
      margin-bottom: 20px;
    }
    &__title {
      font-size: 22px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 1.1px;
      color: #fff;
      margin-bottom: 16px;
      position: relative;
      @include mobile {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.8px;
        margin-bottom: 20px;
      }
      img {
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translate(-100%, -50%);
        height: 30px;
        @include mobile {
          left: -4px;
          height: 29px;
        }
      }
    }
    &__word {
      font-size: 18px;
      font-weight: 400;
      line-height: 200%;
      letter-spacing: 1.8px;
      color: #fff;
      @include mobile {
        font-size: 14px;
      }
      .third-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 200%;
        letter-spacing: 1.8px;
        color: #fff;
        position: relative;
        z-index: 0;
        background-color: transparent;
        padding: 0;
        display: inline-block;
        @include mobile {
          font-size: 16px;
          letter-spacing: 0.8px;
        }
        &:before {
          content: "";
          width: 100%;
          height: 16px;
          background-color: #d3409d;
          border-radius: 4px;
          position: absolute;
          left: 0.5em;
          bottom: 0;
          z-index: -1;
          @include mobile {
            height: 12px;
          }
        }
      }
    }
  }
}
</style>
